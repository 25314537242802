import React, {useState} from 'react'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CustomDatePicker from '../atoms/CustomDatePicker'
import { format } from 'date-fns'
import sendEmail from '../../utility/sendEmail'

const ContactUsForm = (props) => {
  const classes = useStyles()
  const [formFields, setFormFields] = useState({})
  const handleChange = (date, name) => {
    const newsetformFields = { ...formFields, [name]: date, }
    setFormFields(newsetformFields)
  }
  const { translations, } = props
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!e.target.email.value) {
      return null
    }
    const formattedcheckInDate = format(new Date(formFields.checkInDate.year, formFields.checkInDate.month - 1, formFields.checkInDate.day), 'dd/MM/yyyy')
    const formattedcheckOutDate = format(new Date(formFields.checkOutDate.year, formFields.checkOutDate.month - 1, formFields.checkOutDate.day), 'dd/MM/yyyy')

    const templateParams = {
      from_name: e.target.name.value,
      from_email: e.target.email.value,
      phone: e.target.phone.value,
      message: e.target.message.value,
      checkInDate: formattedcheckInDate,
      checkOutDate: formattedcheckOutDate,
    }
    e.target.name.value = ''
    e.target.email.value = ''
    e.target.phone.value = ''
    e.target.message.value = ''
    sendEmail(templateParams, translations)
  }

  const datePickerCustomStyles = {
    backgroundColor: '#f9f9f9',
    border: 'none',
    height: '2.5em',
  }
  const datePickerInputWraperStyles = {
    width: '49%',
  }
  return (
    <div className={classes.contactUsForm}>
      <div className={classes.title}>
        <h2>{ translations.title }</h2>
      </div>
      <div className={classes.formContainer}>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label name="name">{translations.name}</label>
          <input type="text" name="name" className={classes.formInput} />
          <label name="email">{translations.email}</label>
          <input type="email" name="email" className={classes.formInput} />
          <label name="phone">{translations.phone}</label>
          <input type="text" name="phone" className={classes.formInput} />
          <div className={classes.checkInCheckOut}>
            <CustomDatePicker
              name='checkInDate'
              date={formFields.checkInDate}
              setDate={handleChange}
              customStyles={datePickerCustomStyles}
              datePickerInputWraperStyles={datePickerInputWraperStyles}
            />
            <CustomDatePicker
              name='checkOutDate'
              date={formFields.checkOutDate}
              setDate={handleChange}
              customStyles={datePickerCustomStyles}
              datePickerInputWraperStyles={datePickerInputWraperStyles}
            />
          </div>
          <label>{translations.message}</label>
          <textarea name="message" rows="4" className={classes.formInput} style={{ height: 'auto', }} />
          <input type='submit' value={translations.send} className={classes.submitButton}/>
        </form>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  contactUsForm: {
    border: '2px solid #94d224',
    width: '67%',
    color: 'white',
    height: 'fit-content',
    backgroundColor: '#94d224',
    '@media (max-width: 960px)': {
      '&': {
        width: '100%',
      },
    },
  },
  title: {
    marginLeft: '1em',
    '& h2': {
      fontSize: '17px',
    },
  },
  formContainer: {
    backgroundColor: 'white',
    padding: '1em',
    color: '#141414',
    '@media (max-width: 960px)': {
      '&': {
        fontSize: '15px',
        paddingBottom: '1em',
      },
    },
  },
  formInput: {
    width: '100%',
    backgroundColor: '#f9f9f9',
    border: 'none',
    height: '2.2em',
    margin: '1.23em 0em',
    paddingLeft: '1em',
  },
  submitButton: {
    color: 'white',
    backgroundColor: '#94d224',
    border: 'none',
    padding: '0.7em 1em',
    fontWeight: 'bold',
    cursor: 'pointer',
    boxShadow: '2px 2px 4px 2px rgba( 0, 0, 0, 0.5 )',
    transition: 'all .3s',
    '&:hover': {
      backgroundColor: 'white',
      color: '#94d224',
    },
  },
  checkInCheckOut: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '1.3em',
  },
})
ContactUsForm.propTypes = {
  translations: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.contact_page,
  }
}
export default connect(mapStateToProps)(ContactUsForm)
