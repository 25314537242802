import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faTree } from '@fortawesome/free-solid-svg-icons'

const MapAndStreetViewSection= props => {
  const classes = useStyles()
  const { translations, } = props
  return (
    <div className={classes.bottomSection}>
      <a
        className={classes.map}
        href="https://www.google.pl/maps/place/noclegi-dobczyce.pl/@49.8845712,20.0841884,17z/data=!4m5!3m4!1s0x0:0x6db811b2f39f4364!8m2!3d49.884878!4d20.084624"
        target='_blank'
        rel="noreferrer"
      >
      <FontAwesomeIcon icon={faMapMarkerAlt} />
      <h2>{translations.map}</h2>
    </a>
    <a
      className={classes.map}
      style={{ backgroundColor: '#94d224', }}
      href="https://www.google.pl/maps/place/noclegi-dobczyce.pl/@49.8850219,20.0850946,3a,60y,108.23h,79.88t/data=!3m6!1e1!3m4!1sp87tjKo07H6RGihVef3MkQ!2e0!7i13312!8i6656!4m5!3m4!1s0x0:0x6db811b2f39f4364!8m2!3d49.884878!4d20.084624"
      target='_blank'
      rel="noreferrer"
    >
      <FontAwesomeIcon icon={faTree} style={{ color: '#65af1b', }}/>
      <h2>{translations.street_view}</h2>
    </a>
</div>
  )
}

MapAndStreetViewSection.propTypes = {
  translations: PropTypes.object.isRequired,
}

const useStyles = createUseStyles({
  bottomSection: {
    marginTop: '2em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  map: {
    backgroundColor: '#04bafc',
    width: '50%',
    height: '85px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '& svg': {
      height: '5em',
      width: '5em !important',
      color: '#04a8ff',
      padding: '1em 0em',
      marginLeft: '-13px',
      transition: 'all .5s',
    },
    '& svg:hover': {
      transform: 'scale(1.4)',
    },
    '& h2': {
      width: '75%',
      textAlign: 'center',
      color: 'white',
    },
  },
  '@media (max-width: 960px)': {
    map: {
      width: '100%',
    },
  },

})

const mapStateToProps = state => {
  return {
    translations: state.translations.translations.map_and_street_view_section,
  }
}

export default connect(mapStateToProps)(MapAndStreetViewSection)
