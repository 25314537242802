import React from 'react'
import { connect } from 'react-redux'
import { CHANGE_TRANSLATIONS } from '../state/translations'
import { graphql } from 'gatsby'
import ContactPage from '../customPages/contact'

class contactPageTemplate extends React.Component {
  constructor(props) {
    super(props)
    const lang = props.data.allSitePage.edges[0].node.context
    props.changeTranslations(lang)
  }

  render () {
    return (
      <>
        <ContactPage path={this.props.location.pathname} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.home,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTranslations: (payload) => dispatch(CHANGE_TRANSLATIONS(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(contactPageTemplate)

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: {path: {eq: $path}}) {
      edges {
        node {
          context

        }
      }
    }
  }
`
