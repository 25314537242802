import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ContactUsForm from '../components/organisms/ContactUsForm'
import { createUseStyles } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhone, faEnvelope, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'
import MapAndStreetViewSection from '../components/molecules/MapAndStreetViewSection'

const ContactPage = (props) => {
  const classes = useStyles()
  const { translations, path, } = props
  return (
  <Layout path={path}>
    <Seo title={translations.page_title} description={translations.page_description} />
    <div className={classes.contactPage}>
      <div className={classes.leftColumn}>
        <div className={classes.boxWithIcon}>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ backgroundColor: '#0bbafc', }} />
          <div className={classes.info}>
            <span><h4>{translations.adress_box.addres}</h4></span>
            <span> {translations.adress_box.addres_first_line} <br /> {translations.adress_box.addres_second_line} </span>
          </div>
        </div>
        <div className={classes.boxWithIcon}>
          <FontAwesomeIcon icon={faPhone} style={{ backgroundColor: '#94d224', }} />
          <div className={classes.info}>
            <span><h4>{translations.call_us_box.call_us}</h4></span>
            <span> {translations.call_us_box.call_us_first_line} <br /> {translations.call_us_box.call_us_second_line}</span>
          </div>
        </div>
        <div className={classes.boxWithIcon}>
          <FontAwesomeIcon icon={faEnvelope} style={{ backgroundColor: '#666666', }} />
          <div className={classes.info}>
            <span><h4>{translations.email_box.email_title}</h4></span>
            <span> {translations.email_box.email} <br /><br /></span>
          </div>
        </div>
        <div className={classes.boxWithIcon}>
          <FontAwesomeIcon icon={faMoneyBillAlt} style={{ backgroundColor: '#fe7039', }} />
          <div className={classes.info}>
            <span><h4>{translations.advances_box.advances_tile}</h4></span>
            <span style={{ marginTop: '1em', }}>
              {translations.advances_box.account_title} <br />
              {translations.advances_box.account_number_1} <br />
              {translations.advances_box.account_number_2}
            </span>
          </div>
        </div>
      </div>
      <ContactUsForm />
    </div>
    <MapAndStreetViewSection/>
  </Layout>
  )
}

ContactPage.propTypes = {
  translations: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
}

const useStyles = createUseStyles({
  contactPage: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '4em',
    maxWidth: '1300px',
    margin: 'auto',
    padding: '1em',
  },
  leftColumn: {
    width: '33%',
    '@media (max-width: 960px)': {
      '&': {
        width: '100%',
      },
    },
  },
  boxWithIcon: {
    backgroundColor: 'white',
    margin: '0em 1em',
    marginBottom: '1em',
    display: 'flex',
    padding: '1.3em',
    '& svg': {
      height: '5em',
      width: '5em !important',
      color: 'white',
      padding: '1.6em',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '1em',
    fontSize: '16px',
    lineHeight: '21px',
    '& h4': {
      display: 'inline',
      color: '#6B7671',
    },
  },
})

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.contact_page,
  }
}

export default connect(mapStateToProps)(ContactPage)
